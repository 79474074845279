<template>
  <div class="bg-white">
    <div
      class="
        max-w-9xl
        mx-auto
        py-4
        px-4
        w-full
        sm:px-6
        lg:pl-8 lg:py-4
        space-y-5
        sm:space-y-4
        xl:max-w-none
      "
    >
      <h2 class="lg:text-4xl font-extrabold tracking-tight text-3xl mb-4">
        Projects: 2021 Cycle
      </h2>
      <carousel
        :settings="settings"
        :breakpoints="breakpoints"
        :wrap-around="true"
        class="mx-auto"
      >
        <slide
          class="font-bold text-xl rounded-lg"
          v-for="project in projects"
          :key="project.id"
        >
          <div class="carousel__item relative rounded-lg overflow-y-auto">
            <div class="absolute inset-0 rounded-lg">
              <img
                class="h-full w-full object-cover rounded-lg filter blur-sm"
                :src="project.img"
                :alt="`${project.name} background image`"
              />
              <div
                class="
                  absolute
                  inset-0
                  bg-gray-500
                  mix-blend-multiply
                  rounded-lg
                "
              />
            </div>
            <div class="text-white z-10">
              <h1 class="text-center text-4xl">{{ project.name }}</h1>
              <div class="mt-4 font-bold text-lg">
                Background
                <div class="md:pl-4 pl-1 pr-3 mt-2 font-normal text-base">
                  {{ project.background }}
                </div>
              </div>
              <div class="mt-4 font-bold text-lg">
                Task
                <div
                  class="mt-3 font-normal text-base"
                  v-for="task in project.tasks"
                  :key="task.id"
                >
                  {{ task }}
                </div>
              </div>
              <div v-if="project.references" class="mt-4 font-bold text-md">
                Reference
                <div
                  class="mt-3 font-normal text-base"
                  v-for="reference in project.references"
                  :key="reference.id"
                >
                  {{ reference }}
                </div>
              </div>

              <div v-if="project.demo" class="mt-8 demo__button">
                <router-link
                  :to="project.demo"
                  class="bg-green-400 text-white rounded routerlink"
                >
                  {{ project.demoText }}
                </router-link>
              </div>
            </div>
          </div>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

const projects = [
  {
    name: "Reinforcement Learning With Connect Four",
    background:
      "Connect four is a simple game similar to Tic-Tac-Toe [1]. The game has two players and takes place on a 7X6 grid. During each of their turns, each player places a disk in one of the 7 columns [3]. The disk then falls to the lowest possible position in the column [3]. The first player to line up disks horizontally, vertically, or along a diagonal, wins [3].",
    tasks: [
      "To implement reinforcement learning algorithms to solve the game of Connect Four. This project will focus on the Q Learning algorithm [2].",
    ],
    references: [
      "1. Masters, J. (n.d.). History of Stacking four in a Row GAMES(CONNECT 4TM, Captain's MISTRESS, QUBIC). Retrieved February 24, 2021, from https://www.tradgames.org.uk/games/Four-in-a-row.htm",
      "2. Shyalika, C. (2019, November 16). A beginner’s guide to q-learning. Retrieved February 24, 2021, from https://towardsdatascience.com/a-beginners-guide-to-q- learning-c3e2a30a653c",
      "3. Solving Connect 4: How to Build a Perfect AI. (2016, May 01). Retrieved February 25, 2021, from http://blog.gamesolver.org/solving-connect-four/01-introduction/",
    ],
    demo: "/connect4",
    demoText: "Play the AI",
    img: "/project-backgrounds/connect4.webp",
  },
  {
    name: "Automated Detection of Phenophase From Herbarium Images",
    background:
      "Images of specimens from natural history collections are powerful data sources for understanding ecological change over time. However, annotating these images at scale is very time-intensive, limiting the use of data from current large-scale digitization efforts.",
    tasks: [
      "This project will use deep learning to automatically detect phenological stage from images of plant specimens. Characterizing changes in phenology, or the timing of life history events such as flowering, is critical to understanding how organisms are responding to changing climates. In Phase I, we will initially use annotated images of plant specimens previously described by Lorieul et al. [1], but try to improve their models by instead using a 6 stage classification system [2].",
    ],
    references: [
      "1. Lorieul, Titouan, Katelin D. Pearson, Elizabeth R. Ellwood, et al. 2019. “Toward a Large-Scale and Deep Phenological Stage Annotation of Herbarium Specimens: Case Studies from Temperate, Tropical, and Equatorial Floras.” Applications in Plant Sciences 7 (3): e01233. https://doi.org/https://doi.org/10.1002/aps3.1233.",
      "2. Yost, Jennifer M, Patrick W Sweeney, et al. 2018. “Digitization protocol for scoring reproductive phenology from herbarium specimens of seed plants.” Applications in Plant Sciences 6 (2). John Wiley; Sons Inc.: e1022–e1022. https://doi.org/10.1002/aps3.1022.",
    ],
    img: "/project-backgrounds/Hibiscus_rosa-sinensis.webp",
  },
  {
    name: "Maize Genomics",
    background:
      "In this project, we will be predicting performance of maize from single nucleotide polymorphism (SNP) markers from the “Genomes to Fields” Initiative https://www.genomes2fields.org. Since 2014, more than 2500 corn hybrid varieties have been tested across 162 different environments, including locations in Arkansas.",
    tasks: [
      "1. Genomicprediction:Usedecision-treebasedapproachestopredictmaizephenotype in a particular environment with a high level of accuracy, incorporating information about environment, maize genotype, and controlling for population structure.",
      "2. Genome-wide association study (GWAS): Identify particular SNPs associated with variation in phenotype (the observable characteristic of an organism).",
    ],
    references: [
      "1. Battey, C J, Gabrielle C Coffing, and Andrew D Kern. 2021. “Visualizing population structure with variational autoencoders.” G3 Genes|Genomes|Genetics 11 (1). https://doi.org/10.1093/g3journal/jkaa036.",
      "2. McFarland, Bridget A., Naser AlKhalifah, Martin Bohn, et al. 2020. “Maize Genomes to Fields (G2f): 2014-2017 Field Seasons: Genotype, Phenotype, Climatic, Soil, and Inbred Ear Image Datasets.” BMC Research Notes 13 (1): 71. https://doi.org/10.1186/s13104-020-4922-8.",
    ],
    img: "/project-backgrounds/Amazing_Maize.webp",
  },
  {
    name: "Agricultural Imaging",
    background:
      "Of the three major crops – rice, wheat and maize – rice is by far the most important food crop for people in low- and lower-middle-income countries. Although rich and poor people alike eat rice in low-income countries, the poorest people consume relatively little wheat and are therefore deeply affected by the cost and availability of rice [1].",
    tasks: [
      "Given a dataset of 120 JPEG images of disease infected rice leaves [2], we will explore models for predicting the type of disease present in each. The diseases we will consider are the following:",
      "• Leaf smut",
      "• Brown spot",
      "• Bacterial leaf blight",
    ],
    references: [
      "1. http://ricepedia.org/challenges",
      "2. https://www.kaggle.com/vbookshelf/rice-leaf-diseases",
    ],
    img: "/project-backgrounds/bacterial-blight-rice.webp",
  },
  {
    name: "Natural Langauge Processing",
    background:
      "Natural Language Processing (NLP) is the branch of Artificial Intelligence that attempts to decipher, understand, and make sense of human language.",
    tasks: [
      "In this project, we will investigate a corpus of data collected from social media websites. The project will begin by understanding the data and progress into a development pipeline for generating sentiment analysis, text classification, and question answering.",
    ],
    img: "/project-backgrounds/ThematicAnalysisDelve3.webp",
  },
  {
    name: "Self-Driving Car: Scene Understanding",
    background:
      "Scene understanding is an important component for self-driving cars and requires the ability to perform natural image segmentation and utilize sequence information available from the sequential frames of video. Image segmentation is a core problem that has driven many of this decade’s AI breakthroughs.",
    tasks: [
      "In this project, we will focus on building and training semantic segmentation neural networks to identify key objects in driving scenes such as roads, pedestrians, and other cars.",
    ],
    img: "/project-backgrounds/segmentation.webp",
  },
  {
    name: "Fire Detection: Embedded Machine Learning",
    background:
      "With the ever-present threat of wildfires, the need for efficient and scalable wildfire detection is increasing. Image based fire detection has recently received more and more attention due to great success of deep learning in image classification and object detection. However, it is challenging to deploy deep learning models on embedded systems with limited computing resources and energy supply.",
    tasks: [
      "In this project, we will examine efficient learning models for fire detection and evaluate their real-time performances on embedded systems.",
    ],
    img: "/project-backgrounds/fires.webp",
  },
  {
    name: "Business Analytics: Demand Forecasting",
    background:
      "Given historical sales information for an online store, we want to be able to make predictions about the amount of sales that the shops will generate in the future.",
    tasks: [
      "You are provided with daily historical sales data. The task is to forecast the total amount of products sold in every shop for the test set. Note that the list of shops and products slightly changes every month. Creating a robust model that can handle such situations is part of the challenge [1].",
    ],
    references: [
      "1. https://www.kaggle.com/c/competitive-data-science-predict-future-sales/data",
      "2. https://www.coursera.org/learn/competitive-data-science",
    ],
    img: "/project-backgrounds/demand-forecasting.webp",
  },
];

export default {
  name: "AI-Campus Projects",
  components: { Carousel, Slide, Pagination, Navigation },
  setup() {
    return {
      projects,
    };
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 1024 and up
      1024: {
        itemsToShow: 1.25,
        snapAlign: "center",
      },
    },
  }),
};
</script>

<style lang="scss">
.carousel__item {
  min-height: 100%;
  width: 100%;
  // font-size: 20px;
  display: flex;
  box-sizing: border-box;
  // word-wrap: break-word;
  background-color: rgba(219, 233, 233, 0.253);
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  & h1 {
    text-align: center;
  }

  & .demo__button {
    text-align: center;

    & .routerlink {
      box-sizing: border-box;
      padding: 0.5rem;
      display: inline-block;
    }
  }
}

// .carousel__slide {
//   height: 64rem;
// }

// .carousel__track {
//   height: 1000px;
// }

@media only screen and (max-width: 600px) {
  .carousel__item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .carousel__slide {
    margin: auto;
    height: auto;
  }
}

.carousel__slide {
  width: 80%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.carousel__track {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: black;
}

.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
  border-radius: 2rem;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
</style>
